import i18n from "../../i18n/i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useGetData from "../../hooks/useGetData";
import { ISideBar } from "../../constants/types";

const SideBar = ({
	openSideBar,
	setOpenSideBar,
	isActive,
	setIsActive,
	navbarLinks,
	language,
	setLanguage,
}: ISideBar) => {
	const { t } = useTranslation();
	const { data } = useGetData("application/");
	const [openDropdown, setOpenDropdown] = useState(false);

	const handleMainPage = () => {
		setOpenSideBar(false);
		setIsActive("mainpage");
	};

	const handleRoute = (link: string) => {
		setIsActive(link);
		setOpenSideBar(false);
	};

	const handleDropdown = () => {
		setOpenDropdown(!openDropdown);
	};

	const changeLanguages = (lang: string) => {
		i18n.changeLanguage(lang);
		setLanguage(lang);
		setIsActive("mainpage");
	};

	return (
		<div
			className={`top-0 right-0 w-full py-2 pl-6 sm:pl-14 text-white flex flex-col justify-between  fixed  h-screen z-40 bg-[#040301] ease-out-in duration-1000 ${
				openSideBar ? "translate-x-0 " : "translate-x-full"
			}`}
			lang='tr'>
			<div className='flex items-center justify-between'>
				<div className='w-3/5'>
					<Link to='/' onClick={handleMainPage}>
						<img src='/assets/logo.svg' alt='logo' />
					</Link>
				</div>
				<div className='block pr-6 cursor-pointer lg:hidden sm:pr-14' onClick={() => setOpenSideBar(false)}>
					<img src='/assets/closeSideBar.svg' alt='close-menu' />
				</div>
			</div>

			<div className='flex flex-col items-center h-full mt-8 mr-4 overflow-auto uppercase sm:mr-14 no-scrollbar'>
				{navbarLinks?.map((item: any) => (
					<div key={item.id} className='mt-10'>
						<Link
							onClick={() => {
								item.link === "applications" ? handleDropdown() : handleRoute(item.link);
								setIsActive(item.link);
							}}
							to={item.link === "mainpage" ? "/" : item.link === "applications" ? "#" : item.link}
							className={`relative tracking-wide font-semibold  text-xl ml-4 ${
								isActive === item?.link && "text-[#f8af41d9]"
							}`}>
							{t(`navbar.${item.link}`).toLocaleUpperCase("en-EN")}

							{item.link === "applications" && (
								<img
									src='/assets/arrow.svg'
									alt='dropdown'
									className={`absolute right-[-40px] top-2 ${openDropdown && "rotate-180"}`}
								/>
							)}
						</Link>
						{item?.link === "applications" &&
							data?.map((subLink: any) => (
								<div
									key={subLink?.id}
									className={`px-3 py-4 text-xs text-center text-white capitalize  ${
										openDropdown ? "block" : "hidden"
									}`}>
									<Link to={`applications/${subLink?.link}/`} onClick={() => setOpenSideBar(false)}>
										{subLink?.title}
									</Link>
								</div>
							))}
					</div>
				))}

				<div className='mt-20'>
					<Link
						to='/'
						className='flex items-center px-4 py-1 font-bold text-center bg-black border border-white rounded-md w-max text-orange'
						onClick={() => changeLanguages(language === "en" ? "hu" : "en")}>
						{t("language")}
					</Link>
				</div>
			</div>
			<div className='flex justify-center pt-4 mb-10 mr-6 text-xs border-t-2 border-white sm:mr-32 sm:ml-28'>
				FEHER CONSTRUCTION KFT. 2021 {t("footer.rights")}
			</div>
		</div>
	);
};

export default SideBar;
