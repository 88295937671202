import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IHome } from "../../constants/types";
import useGetData from "../../hooks/useGetData";

interface IApplication {
	id?: number;
	description?: string;
	image?: string;
	language?: string;
	link?: string;
	title?: string;
}

const Footer = ({ setIsActive }: IHome) => {
	const { t } = useTranslation();
	const { data } = useGetData("application/");
	const contactData: any = useGetData("contact/");

	return (
		<div className='flex flex-col lg:pt-16 lg:pl-16 pl-6 pt-8 border-2 bg-[#040301] text-white md:pr-32 lg:pr-52 '>
			<div className='flex flex-col justify-between md:flex-row'>
				<div className=' md:w-[36%] w-[85%] '>
					<div>
						<Link to='/'>
							<img src='/assets/logo.svg' alt='logo' />
						</Link>
					</div>
					<div className='pt-8 text-xs'>{t("footer.description")}</div>
				</div>
				<div className=' md:w-[16%] w-[65%] pt-8 '>
					<div className='pb-2 text-lg tracking-wide border-b-2 '>
						{t("navbar.applications").toLocaleUpperCase("en-EN")}
					</div>
					<div className='pt-4 '>
						{data?.map((item: IApplication) => (
							<div onClick={() => setIsActive("applications")} className='pt-4 text-xs' key={item.id}>
								<Link to={`applications/${item.link}`}>{item.title}</Link>
							</div>
						))}
					</div>
				</div>
				<div className=' md:w-[21%] w-[65%] pt-8 '>
					<div className='pb-2 text-lg tracking-wide border-b-2 '>{t("navbar.contact").toLocaleUpperCase("en-EN")}</div>
					<div className='pt-8 text-xs'>
						<div className=''>
							<span className='capitalize'> {t("footer.address")}: </span> <br />
							<span>{contactData?.data[0]?.address || ""}</span>
						</div>
						<div className='pt-4'>
							<span className='capitalize'>{t("footer.phone")}:</span> <br />
							<a href='tel:+36707720883'>{contactData?.data[0]?.phone || ""}</a>
						</div>

						<div className='pt-4'>
							<span className='capitalize'>{t("footer.email")}:</span> <br />
							<a href='mailto:office@feherconstruction.com'>{contactData?.data[0]?.email || ""}</a>
						</div>
						<div className='pt-4'>
							<span className='capitalize'>{t("footer.hours")}:</span> <br />
							<span>{t("footer.days")}: 09:00 / 17:00</span>
						</div>
					</div>
				</div>
			</div>
			<div className='flex pt-6 lg:justify-end lg:pt-24 '>
				<div className='border-t-2 py-6 lg:w-[85%] w-[92%] text-center text-xs'>
					<span className='uppercase'>FEHER CONSTRUCTION KFT.</span> 2021 {t("footer.rights")}
				</div>
			</div>
		</div>
	);
};

export default Footer;
