import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { navbarLinks } from "../../constants/navbarLinks";
import SideBar from "../SideBar";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n/i18n";
import useGetData from "../../hooks/useGetData";

interface INavbar {
	language?: string;
	setLanguage?: any;
	isActive?: string;
	setIsActive?: any;
}

const Navbar = ({ language, setLanguage, isActive, setIsActive }: INavbar) => {
	const { t } = useTranslation();
	const { data } = useGetData("application/");
	const location = useLocation();
	const [openDropdown, setOpenDropdown] = useState(false);
	const [openSideBar, setOpenSideBar] = useState(false);

	const handleDropdown = () => {
		setOpenDropdown(!openDropdown);
	};

	const handleRoute = (link: string) => {
		setIsActive(link);
		setOpenSideBar(false);
		setOpenDropdown(false);
	};

	const changeLanguages = (lang: string) => {
		i18n.changeLanguage(lang);
		setLanguage(lang);
		setIsActive("mainpage");
	};

	return (
		<>
			<div className='flex justify-between items-center py-2 pl-6 sm:pl-14 bg-[#040301] text-white uppercase' lang='tr'>
				<div className='w-3/5 lg:w-1/5'>
					<Link to='/' onClick={() => setIsActive("mainpage")}>
						<img src='/assets/logo.svg' alt='logo' />
					</Link>
				</div>
				<div className='items-center justify-end hidden lg:flex xl:w-3/5'>
					<div className='mx-4 xl:mx-10 '>
						<Link
							to='/'
							className='flex items-center px-4 py-1 font-bold text-center bg-black border border-white rounded-md w-max text-orange'
							onClick={() => changeLanguages(language === "en" ? "hu" : "en")}>
							{t("language")}
						</Link>
					</div>

					{navbarLinks?.map((link: any) => (
						<div
							key={link.id}
							onBlur={() => (location.pathname === "/" ? "mainpage" : location.pathname.substring(1))}
							className='relative'>
							<Link
								onClick={() => {
									link.link === "applications" ? handleDropdown() : handleRoute(link.link);
									setIsActive(link.link);
								}}
								to={link.link === "mainpage" ? "/" : link.link === "applications" ? "#" : `${link.link}/`}
								className={`flex justify-center px-8 xl:w-44 truncate  tracking-wide uppercase font-semibold ${
									isActive === link?.link && "text-[#f8af41d9]"
								}`}>
								{t(`navbar.${link.link}`).toLocaleUpperCase("en-EN")}
								<div
									className={`${
										openDropdown && link.link === "applications"
											? "absolute bg-[#040301] m-4 p-6 left-[-27%] top-4 z-40"
											: "hidden"
									}`}>
									{data?.map((subLink: any) => (
										<div
											key={subLink?.id}
											className='px-3 py-4 text-xs text-center text-white capitalize hover:text-[#f8af41d9]'>
											<Link to={`/applications/${subLink?.link}/` || `/applications/Acoustic-Ceiling/`}>
												{subLink?.title}
											</Link>
										</div>
									))}
								</div>
							</Link>
						</div>
					))}
				</div>
				<div className='block pr-6 cursor-pointer lg:hidden sm:pr-14' onClick={() => setOpenSideBar(true)}>
					<img src='/assets/hamburgerMenu.svg' alt='hamburger-menu' />
				</div>
			</div>
			<div className='block lg:hidden'>
				<SideBar
					openSideBar={openSideBar}
					setOpenSideBar={setOpenSideBar}
					isActive={isActive}
					setIsActive={setIsActive}
					navbarLinks={navbarLinks}
					language={language}
					setLanguage={setLanguage}
				/>
			</div>
		</>
	);
};

export default Navbar;
